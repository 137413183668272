.faq-container {
  padding: 20px;
  max-width: 900px;
  margin: auto;
}

.faq-container h2 {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
}

.faq-item {
  margin-bottom: 15px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.faq-item h3 {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  color: var(--text-color);
  height: auto;
  cursor: pointer;
}

.faq-answer {
  margin-top: 8px;
  font-size: 1rem;
  color: var(--text-muted);
}

@media (max-width: 768px) {
  .faq-container {
    padding: 15px;
  }

  .faq-container h2 {
    font-size: 1.8rem;
  }

  .faq-item h3 {
    font-size: 1.1rem;
  }

  .faq-answer {
    font-size: 0.95rem;
  }
}

@media (max-width: 480px) {
  .faq-container {
    padding: 10px;
    max-width: 100%;
  }

  .faq-container h2 {
    font-size: 1.5rem;
  }

  .faq-item h3 {
    font-size: 1rem;
  }

  .faq-answer {
    font-size: 0.9rem;
  }
}
