.logo__container {
  gap: 5px;
}

.logo__container .logo {
  width: 50px;
  height: 50px;
}

.logo__container img {
  object-fit: contain;
}

.logo__container h2 {
  font-size: 20px;
}

@media (min-width: 950px) and (max-width: 1117px) {
  .logo__container h2 {
    font-size: 16px;
  }
}
