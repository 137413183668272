#hero {
  position: relative;
  background: var(--bg-secondary);
  background-image: url("../../assets/structure.jpg");
  background-size: cover;
}

#hero .container {
  display: grid;
  grid-template-columns: 50% 50%;
  padding-top: 40px;
  min-height: 100vh;
}

#hero .hero__content {
  margin-bottom: 30px;
}

#hero .hero__content .heading {
  margin: 1.5rem 0;
  background: linear-gradient(black, orangered, var(--primary));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 67px;
}

#hero .hero__content .description {
  font-size: var(--font-size-large);
}

#hero .buttons__wrapper {
  justify-content: flex-start;
  margin-top: 20px;
}

@media (min-width: 1500px) {
  #hero .container {
    min-height: auto;
  }
}

@media (max-width: 1260px) {
  #hero .hero_content .heading {
    font-size: 40px;
    line-height: 45px;
  }
  #hero .hero__content .description {
    font-size: var(--font-size-base);
  }
}

@media (max-width: 930px) {
  #hero .container {
    display: block;
  }

  #hero .hero__content {
    text-align: center;
  }
  #hero .buttons__wrapper {
    justify-content: center;
  }

  #hero .hero__image-container {
    max-width: 400px;
    margin: auto;
    margin-top: 40px;
  }
}

@media (max-width: 768px) {
  #hero.blur__effect::before {
    top: -150px;
  }
}

@media (max-width: 500px) {
  #hero .hero__content .heading {
    font-size: 25px;
    line-height: 30px;
  }
}
