#footer {
  background-image: url("../../assets/structure.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
#footer .container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
}
#footer .logo__container {
  justify-content: flex-start;
}
#footer .title {
  font-size: 18px;
}
#footer .route {
  color: var(--text-color);
  display: block;
  margin: 20px 0;
}
#footer .footer__bottom {
  text-align: center;
  padding: 20px;
}

@media (max-width: 930px) {
  #footer .container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 768px) {
  #footer .container {
    grid-template-columns: 100%;
  }
}
