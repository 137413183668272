#blog {
  background: var(--primary);
  border-top-right-radius: var(--radius-5);
}
#blog .container {
  max-width: 1100px;
  margin: auto;
  display: grid;
  grid-template-columns: auto 600px;
  gap: 1.5rem;
}
#blog .blogs__container {
  height: 100%;
}
#blog .blog {
  height: inherit;
  background: var(--bg-secondary);
  border-radius: var(--radius-3);
  border: solid 10px var(--primary);
  overflow: hidden;
}
#blog .blog .title {
  font-size: var(--font-size-base);
}
#blog .blog .details {
  padding: 20px;
}

@media (max-width: 930px) {
  #blog .container {
    grid-template-columns: 100%;
  }
}
